<script>

import Editor from '@tinymce/tinymce-vue'
import FileManager from "@/views/apps/filemanager";


export default {
  name: 'TinyMCEEditor',
  props: {
    content: String,
    id: String,
    contentChanged: { type: Function }
  },
  data() {
    const self = this;
    return {
      tinyMceKey: process.env.VUE_APP_TINYMCE_KEY,
      tinyMCEOptions: {
        plugins: [ "image", "code", "table", "link", "media", "codesample"],
        toolbar: 'undo redo | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | tinyMceImageToolbarButton',
        height: 700,
        toolbar_sticky: true,
        toolbar_sticky_offset: 65,
        importcss_append: true,
        image_caption: true,
        quickbars_selection_toolbar: false,
        quickbars_insert_toolbar: false,
        setup: function (editor) {
          self.onSetUpTinyMce(editor);
        }
      },
      tinyEditorController: null,
      editorContent: ''
    };
  },
  mounted() {
  },
  methods: {
    openFileManagerPopup() {
      this.$refs.fileManager.onOpenFileManager();
    },
    onSetUpTinyMce(editor){
      const self = this;
      this.tinyEditorController = editor;
      editor.ui.registry.addButton('tinyMceImageToolbarButton', {
        text: '',
        icon: 'embed-page',
        onAction: function () {
          self.openFileManagerPopup();
        }
      });
    },
    onInsertFile(result) {
      const imgHtml = '<figure class="image"><img class="blog-content-image" src="'+result.fullUrl+'" file_id="'+result.id+'" /><figcaption>Caption</figcaption></figure>';
      this.tinyEditorController.execCommand('mceInsertContent', false, imgHtml);
    },
    onChangeContent() {
      this.contentChanged(this.editorContent);
    },
    reloadContent(content) {
      this.editorContent = content;
    }
  },
  components: {
    FileManager,
    editor: Editor
  }
}
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="ckeditor-classic">
        <editor
            :api-key="tinyMceKey"
            :init="tinyMCEOptions"
            :id="id"
            :initial-value="content"
            v-model="editorContent"
            @change="onChangeContent"
        />
      </div>
    </div>
  </div>
  <FileManager
      ref="fileManager"
      :onSelectedFunc="onInsertFile"
  />
</template>
